const rootEl = document.documentElement;

function openModal(e) {
  const clicked = e.currentTarget;
  const parent = clicked.closest('.js-person');
  parent.classList.add('person-modal-is-active');
  rootEl.classList.add('modal-is-active');
  const personSlug = parent.dataset.slug;
  // Build new URL
  const locationHref = window.location.href;
  // Check for trailing slash
  let baseURL = locationHref.slice(-1) === '/' ? locationHref : `${locationHref}/`;
  const newURL = `${baseURL}${personSlug}`;
  history.pushState(newURL, null, newURL);
}

function closeModal(e) {
  const clicked = e.currentTarget;
  const parent = clicked.closest('.js-person.person-modal-is-active');
  parent.classList.remove('person-modal-is-active');
  rootEl.classList.remove('modal-is-active');
  // Build new URL
  const personSlug = parent.dataset.slug;
  const locationHref = window.location.href;
  const newURL = locationHref.substring(0, locationHref.indexOf(`/${personSlug}`));
  history.pushState(newURL, null, newURL);
}

function filter(e) {
  e.preventDefault();
  const clicked = e.currentTarget;
  const newURL = clicked.href;
  // Update matching people
  const team = clicked.dataset.team;
  const matching = document.querySelectorAll(`.js-person[data-team*="${team}"]`);
  const people = document.querySelectorAll('.js-person.person-is-visible');
  const activeTeam = document.querySelector('.team-is-active');

  if (activeTeam) {
    activeTeam.classList.remove('team-is-active');
  }

  clicked.classList.add('team-is-active');
  people.forEach(person => person.classList.remove('person-is-visible'));
  matching.forEach(match => match.classList.add('person-is-visible'));

  // Update url
  history.pushState(newURL, null, newURL);
}

function handlePopState(event) {
  // Check to make sure doesn't fire because of filters.js
  if (event.state && window.location.href.includes('our-people')) {
    const stateURL = typeof event.state === 'string' ? event.state : '';
    const segments = window.location.pathname.split('/').filter(item => item.length > 0);
    const team = segments[1];
    // Update active team
    const currentTeam = document.querySelector('.team-is-active');
    if (currentTeam) {
      currentTeam.classList.remove('team-is-active');
    }

    const matchingLink = document.querySelector(`.js-filter-team[data-team*="${team}"]`);
    const matchingPeople = Array.from(document.querySelectorAll(`.js-person[data-team*="${team}"]`));
    const currentPeople = Array.from(document.querySelectorAll('.js-person.person-is-visible'));
    currentPeople.forEach(person => person.classList.remove('person-is-visible'));
    matchingLink.classList.add('team-is-active');
    matchingPeople.forEach(match => match.classList.add('person-is-visible'));

    // Add/remove modal depending on the URL segment length
    // Check if any active modal and hide it
    const currentModal = document.querySelector('.person-modal-is-active');
    if (currentModal) {
      currentModal.classList.remove('person-modal-is-active');
      rootEl.classList.remove('modal-is-active');
    }

    // If URL contains person show that modal
    const person = segments[2];
    if (!person) {
      return;
    }
    const matchPerson = document.querySelector(`.js-person[data-slug*="${person}"][data-team*=${team}]`);
    if (!matchPerson) {
      return;
    }
    matchPerson.classList.add('person-modal-is-active');
    rootEl.classList.add('modal-is-active');
  }
}

const team = {
  init() {
    const container = document.querySelector('#people');
    if (!container) {
      return;
    }

    const initialTeam = container.dataset.initialTeam;
    console.log(initialTeam);
    container.classList.remove('people-is-loading');

    const filterLinks = document.querySelectorAll('.js-filter-team');
    if (filterLinks.length) {
      filterLinks.forEach(link => {
        link.addEventListener('click', filter);
      });
    }

    const modalBtns = document.querySelectorAll('.js-open-person');
    const closeBtns = document.querySelectorAll('.js-close-person-modal');

    if (modalBtns.length) {
      modalBtns.forEach(btn => btn.addEventListener('click', openModal));
    }

    if (closeBtns.length) {
      closeBtns.forEach(btn => btn.addEventListener('click', closeModal));
    }

    window.addEventListener('popstate', handlePopState);

    if (typeof history.replaceState !== 'undefined') {
      // Store an initial state, so the back button works properly when returning to the page you started on:
      history.replaceState(window.location.href, null, window.location.href)
    }
  }
}

export default team;
