import search from './search'
import gsap from 'gsap'

function toggleSubMenu(e) {
  const currentActive = document.querySelector('.submenu-is-active');
  const clickedParent = e.currentTarget.closest('.js-submenu-parent');

  if (currentActive && currentActive !== clickedParent && !clickedParent.classList.contains('js-submenu-parent-mobile')) {
    currentActive.classList.remove('submenu-is-active');
    const childList = currentActive.querySelector('.js-nav-children');
    gsap.set(childList, {clearProps: 'all'});
  }

  clickedParent.classList.toggle('submenu-is-active');
  const childList = clickedParent.querySelector('.js-nav-children');

  if (clickedParent.classList.contains('submenu-is-active')) {
    gsap.from(childList, {
      duration: 0.2,
      opacity: 0,
      height: 0
    });
  } else {
    gsap.set(childList, {clearProps: 'opacity,height'});
  }
}

const nav =  {
  hideSubMenu() {
    const currentActive = document.querySelector('.submenu-is-active');

    if (currentActive) {
      const childList = currentActive.querySelector('.js-nav-children');
      currentActive.classList.remove('submenu-is-active');
      gsap.set(childList, {clearProps: 'opacity,height'});
    }
  },

  init() {
    const btns = document.querySelectorAll('.js-submenu-btn');
    const scrollLinks = document.querySelectorAll('a.js-scroll');

    if (scrollLinks.length) {
      scrollLinks.forEach((link) => {
        link.addEventListener('click', function(e) {
          const currentUrl = window.location.href.split('#')[0];
          const clicked = e.currentTarget.href.split('#');
          const clickedUrl = clicked[0];

          if (currentUrl === clickedUrl) {
            e.preventDefault();
            const selector = `#${clicked[1]}`;
            const matching = document.querySelector(selector);

            if (matching) {
              history.pushState(null, null, selector);
              gsap.to(window, {
                duration: 0.5,
                scrollTo: selector
              });
            }
          }
        });
      });
    }

    if (btns.length) {
      btns.forEach(btn => {
        btn.addEventListener('click', function(e) {
          toggleSubMenu(e);
          search.hideSearch();
        });
      });

      document.addEventListener('keydown', (e) => {
        if (e.code === 'Escape') {
          this.hideSubMenu();
        }
      });
    }
  }
}

export default nav;
