import Glide from '@glidejs/glide';
const rootEl = document.documentElement;
const carousel = document.querySelector('#featured-carousel');

function showModal(e) {
  e.preventDefault();
  const selector = e.currentTarget.getAttribute('href');
  const modal = document.querySelector(selector);

  if (!modal) {
    return;
  }

  modal.classList.add('modal-is-active');
  rootEl.classList.add('modal-is-active');
}

function hideModal(e) {
  const modal = e.currentTarget.closest('.js-featured-modal');
  modal.classList.remove('modal-is-active');
  rootEl.classList.remove('modal-is-active');
}

function calcBtnPos() {
  const imgHeight = carousel.querySelector('img').offsetHeight;
  const btns = carousel.querySelector('.featured__arrows');
  const btnHeight = btns.offsetHeight;
  btns.style.top = `${imgHeight/2 - btnHeight/2}px`;
}

export default function() {
  if (!carousel) {
    return;
  }

  const glide = new Glide('#featured-carousel', {
    autoplay: 3000,
    hoverpause: true,
    type: 'carousel',
    startAt: 0,
    gap: 12,
    perView: 2,
    breakpoints: {
      420: {
        perView: 1,
      },
      768: {
        perView: 2,
      },
      960: {
        perView: 1,
      },
    }
  });

  glide.on('mount.before', function() {
    carousel.classList.add('carousel-is-loading');
  });

  glide.on('mount.after', function() {
    carousel.classList.remove('carousel-is-loading');
    carousel.classList.add('carousel-is-loaded');
    calcBtnPos();
  });

  glide.on('resize', calcBtnPos);
  glide.mount();

  const showModalBtns = Array.from(document.querySelectorAll('.js-show-featured-modal'));
  showModalBtns.forEach(item => {
    item.addEventListener('click', showModal);
  });

  const hideModalBtns = Array.from(document.querySelectorAll('.js-close-featured-modal'));
  hideModalBtns.forEach(item => {
    item.addEventListener('click', hideModal);
  });
}
