import gsap from 'gsap';

const body = document.body;
const scrollClass = 'page-scrolling-down';
let prevScrollY = window.scrollY;

function initScrollListener() {
  // Setup a timer
  let timeout;
  // Listen for scroll events
  window.addEventListener('scroll', function(event) {
  	// If there's a timer, cancel it
  	if (timeout) {
  		window.cancelAnimationFrame(timeout);
  	}
    // Setup the new requestAnimationFrame()
  	timeout = window.requestAnimationFrame(function() {
      // Run our scroll function
      checkScroll();
  	});
  }, false);
}

function checkScroll() {
  const newScrollY = window.scrollY;

  if (newScrollY > 0) {
    body.classList.add(scrollClass);
  } else {
    body.classList.remove(scrollClass);
  }
  prevScrollY = newScrollY;
}

export default function() {
  initScrollListener();
}
