import mobileNav from './mobile-nav';
import nav from './nav';
import axios from 'axios';
import gsap from 'gsap';
let source;

function initResizeListener() {
  // Setup a timer
  let timeout;
  // Listen for scroll events
  window.addEventListener('resize', function(event) {
  	// If there's a timer, cancel it
  	if (timeout) {
  		window.cancelAnimationFrame(timeout);
  	}
    // Setup the new requestAnimationFrame()
  	timeout = window.requestAnimationFrame(function() {
      // Run our scroll function
      calcOffset();
  	});
  }, false);
}

function toggleSearch() {
  const rootEl = document.documentElement;
  rootEl.classList.toggle('search-is-active');

  if (rootEl.classList.contains('search-is-active')) {
    const searchInput = document.querySelector('#search-input')
    if (searchInput) {
      searchInput.focus()
    }
  }
}

function ajaxSearch(e) {
  const rootEl = document.documentElement
  rootEl.classList.add('search-is-searching')
  const searchForm = e.target
  const searchContainer = document.querySelector('#ajax-search-container')
  const url = searchForm.getAttribute('action')
  const formData = new FormData(searchForm)

  axios({
    method: 'post',
    url: url,
    data: formData
  })
  .then((res) => {
    const resDoc = document.createRange().createContextualFragment(res.data)
    const resultsEl = resDoc.getElementById('search-results')
    searchContainer.innerHTML = ''
    searchContainer.append(resultsEl)
    rootEl.classList.remove('search-is-searching')
    gsap.from('.js-search-result', {
      y: -10,
      opacity: 0,
      stagger: 0.1
    })
  })
  .catch((thrown) => {
    console.log(thrown)
  })
}

function calcOffset() {
  // Get header height for top offset
  const header = document.getElementById('header');
  const height = header.offsetHeight;
  const searchMain = document.getElementById('search-main');
  searchMain.style.top = `${height}px`;
}

const search = {
  hideSearch() {
    const rootEl = document.documentElement
    rootEl.classList.remove('search-is-active')
  },

  init() {
    const searchOpenBtn = document.querySelector('#search-open-btn');
    const searchForm = document.querySelector('#search-form');
    calcOffset();
    initResizeListener();

    if (searchOpenBtn) {
      searchOpenBtn.addEventListener('click', function(e) {
        toggleSearch()
        mobileNav.hideMobileNav()
        nav.hideSubMenu()
      })

      document.addEventListener('keydown', (e) => {
        const searchIsOpen = document.querySelector('.search-is-active')
        if (e.code === 'Escape' && searchIsOpen) {
          this.hideSearch()
        }
      })
    }

    if (searchForm) {
      searchForm.addEventListener('submit', function(e) {
        e.preventDefault()
        ajaxSearch(e)
      });
    }
  }
}

export default search
