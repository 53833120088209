import axios from 'axios';
import gsap from 'gsap';
let mapContainer;
let mapEl;
let stories;
let items;
let mapFilterInputs;
let markers = [];

function hideActiveMapCard() {
  const currentCard = document.querySelector('.map-card-is-active');
  const currentMarker = document.querySelector('.marker-is-active');
  if (currentMarker) {
    currentMarker.classList.remove('marker-is-active');
  }
  if (currentCard) {
    currentCard.classList.remove('map-card-is-active');
    gsap.to(currentCard, {
      opacity: 0,
      display: 'none',
      duration: 0.1
    });
  }
}

function showCard(card) {
  card.classList.add('map-card-is-active');
  gsap.fromTo(card, { 
    y: 20, 
    opacity: 0 
  }, { 
    y: 0, 
    opacity: 1, 
    duration: 0.3, 
    ease: 'power2.out',
    display: 'block'
  });
}

function addMarkers() {
  if (items) {
    items.forEach((item, i) => {
      const data = item.result;
      if (!data) return;
      const match = stories.find(story => story.postcode.replace(/\s/g, '').toLowerCase() === data.postcode.replace(/\s/g, '').toLowerCase());

      if (match) {
        // create a HTML element for each feature
        const el = document.createElement('div');
        el.className = 'fieldguide__marker';
        el.dataset.id = `map-card-${match.id}`;

        el.addEventListener('click', function(e) {
          hideActiveMapCard();
          const clicked = e.target;
          clicked.classList.add('marker-is-active');
          console.log(clicked);
          const id = clicked.dataset.id;
          const card = document.getElementById(id);
          showCard(card);
        });

        const card = 
        `<div class="map-card js-map" id="map-card-${match.id}">
          <a class="card card--${match.type}" href="${match.url}">
            <div class="card__container">
              <div class="card__image-container">
                <img class="card__image" srcset="${match.thumb}" alt="">
              </div>
              <div class="card__tag">
                <span>${match.typeTitle}</span>
              </div>

              <div class="card__text">
                <div class="card__top">
                  <h5 class="card__title">${match.title}</h5>
                  <div class="card__date">${match.date}</div>
                </div>
        
                <div class="card__snippet">${match.snippet}</div>
        
                <div class="card__bottom">
                  <div class="more">
                    <span class="more__text">Read more</span>
                    <div class="more__arrow"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 14"><path d="M35 7H0m29-5.657L34.657 7 29 12.657" fill="none" fill-rule="evenodd" stroke="#662450" stroke-width="2"/></svg></div>
                  </div>

                  <button class="btn btn--extra-slim js-close-map-card">Close</button>
                </div>
              </div>
            </div>
          </a>
        </div>`;

        const container = document.getElementById('map-cards');
        container.insertAdjacentHTML('beforeend', card);

        let marker = new mapboxgl.Marker({
          element: el,
          anchor: 'bottom'
        })
        .setLngLat([data.longitude, data.latitude])
        .addTo(mapEl);
        marker.type = match.type;
        marker.topics = match.topics;
        markers.push(marker);
      }
    })

    mapContainer.classList.remove('map-is-loading');
    map.updateMarkers();
  }
}

function getStories(url) {
  axios({
    url: url,
  }).then(res => {
    stories = res.data.data;
    const postcodes = stories.map(story => story.postcode);
    return axios({
      method: 'post',
      url: 'https://api.postcodes.io/postcodes',
      data: {
        postcodes: postcodes
      }
    });
  }).then(res => {
    items = res.data.result;
    addMarkers();
  });
}

function initControls() {
  const zoomIn = document.querySelector('#zoom-in');
  const zoomOut = document.querySelector('#zoom-out');

  zoomIn.addEventListener('click', function(e) {
    mapEl.zoomIn();
  });

  zoomOut.addEventListener('click', function(e) {
    mapEl.zoomOut();
  });
}

function setCenterAndZoom() {
  if (mapEl) {
    let center = [-4.5, 54.5];
    let zoom = 4.5;
    const windowWidth = window.innerWidth;

    if (windowWidth > 550 && windowWidth <= 768) {
      center = [-4, 54];
      zoom = 5;
    } else if (windowWidth > 768) {
      center = [-5, 54];
      zoom = 5;
    }

    mapEl.setZoom(zoom);
    mapEl.setCenter(center);
  }
}

function isTouchEvent(e) {
  return e.originalEvent && "touches" in e.originalEvent;
}

function isTwoFingerTouch(e) {
  return e.originalEvent.touches.length >= 2;
}

const map = {
  resetMarkers() {
    markers.forEach((marker, i) => {
      marker.remove();
      marker.addTo(mapEl);
    })
  },

  resetMapFilters() {
    mapFilterInputs.forEach(filter => filter.checked = false);
  },

  updateMarkers() {
    const form = document.getElementById('map-filters-form');
    const activeFilters = Array.from(form.querySelectorAll('.js-map-filter-input:checked'));

    if (activeFilters.length) {
      let filterObjects = activeFilters.map((item) => {
        let obj = {};
        obj.group = item.dataset.group;
        obj.value = item.value;
        return obj;
      });

      let grouped = filterObjects.reduce((r, a) => {
       r[a.group] = [...r[a.group] || [], a];
       return r;
      }, {});

      markers.forEach(marker => {
        let matchGroup = true;
        let matchTopic = true;

        if (grouped.type) {
          matchGroup = false;
          matchGroup = grouped.type.find(item => item.value === marker.type);
        }

        if (grouped.topic) {
          matchTopic = grouped.topic.find(item => marker.topics.includes(item.value));
        }

        marker.remove();

        if (matchGroup && matchTopic && mapEl) {
          marker.addTo(mapEl);
          return marker;
        } else {
          const id = marker.getElement().dataset.id;
          const markerCardActive = document.querySelector(`#${id}.map-card-is-active`);
          if (markerCardActive) {
            hideActiveMapCard();
          }
        }
      });
    } else {
      // If no filters selected, show all markers. Re-add all markers to map
      map.resetMarkers();
    }
  },

  init() {
    mapContainer = document.querySelector('#fieldguide-map');
    mapFilterInputs = document.querySelectorAll('.js-map-filter-input');
    
    let url = 'stories.json';

    if (mapContainer) {
      mapboxgl.accessToken = 'pk.eyJ1IjoiZmZjYyIsImEiOiJja2NrbzExa2UxOHRkMnRvM2RqZHBnN2plIn0.u8V2s1BfxESTrSMIh1MlWA';
      mapEl = new mapboxgl.Map({
        container: mapContainer,
        style: 'mapbox://styles/ffcc/ckckq2bmc58au1io0zl9b7dl4',
        maxZoom: 10,
        pitchWithRotate: false,
        scrollZoom: false,
        touchPitch: false,
        doubleClickZoom: false
      });

      mapEl.touchZoomRotate.enable({ around: 'center' });
      mapEl.touchZoomRotate.disableRotation();

      mapEl.on('dblclick', function(e) {
        mapEl.zoomIn();
      })

      setCenterAndZoom();
      initControls();
      getStories(url);

      // Temp fix for multitouch/scroll issue on mobile until next Mapbox release:
      // https://github.com/mapbox/mapbox-gl-js/issues/2618
      mapEl.on("dragstart", event => {
        if (isTouchEvent(event) && !isTwoFingerTouch(event)) {
          mapEl.dragPan.disable();
        }
      });

      // Drag events not emited after dragPan disabled, so I use touch event here
      mapEl.on("touchstart", event => {
        if (isTouchEvent(event) && isTwoFingerTouch(event)) {
          mapEl.dragPan.enable();
        }
      });

      mapEl.on('resize', function(e) {
        map.updateMarkers();
      });

      mapFilterInputs.forEach((filter, i) => {
        filter.addEventListener('change', function(e) {
          map.updateMarkers();
        });
      });

      document.addEventListener('click', function(e) {
        const closeCardButton = e.target.closest('.js-close-map-card');
        if (closeCardButton) {
          e.preventDefault();
          hideActiveMapCard();
        }
      });
    }
  }
}

export default map;
