import gsap from 'gsap';
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);

let offset = 0;
let sectionNav;
let hubHero;

function calcScrollOffset() {
  if (!sectionNav) return;
  const top = parseFloat(window.getComputedStyle(sectionNav).top);
  offset = sectionNav.offsetHeight + top - 1;
}

function initSection(section) {
  const id = section.id;
  const navLinks = Array.from(document.querySelectorAll('.js-nav-link'));
  const link = document.querySelector(`.js-nav-link[href="#${id}"]`);

  if (id && navLinks.length && link) {
    ScrollTrigger.create({
      trigger: section,
      start: `top top+=${offset+2}px`,
      onEnter: () => {
        link.classList.add('section-is-scrolled');
      },
      onLeaveBack: () => {
        link.classList.remove('section-is-scrolled');
      }
    });
  }
}

function handleLink(e) {
  e.preventDefault();
  let selector = e.currentTarget.getAttribute('href');
  const el = document.querySelector(selector);
  if (el) {
    gsap.to(window, {
      duration: 1,
      scrollTo: {
        y: el,
        offsetY: offset,
      },
      ease: "power2.inOut"
    });
  }
}

// Init sections is for the sticky in-page nav
function initSections() {
  const sections = Array.from(document.querySelectorAll('.js-scroll-section'));
  if (!sections.length) return;
  
  sections.forEach(section => initSection(section));
}

// This is for detecting any other links on the page that could be in-page scroll-to links
function initInPageLinks() {
  const links = document.querySelectorAll('a[href^="#"]');
  links.forEach(link => {
    link.addEventListener('click', handleLink);
  });
}

export default function() {
  hubHero = document.querySelector('#hub-hero');
  sectionNav = document.querySelector('#hub-nav') || document.querySelector('#section-nav');

  calcScrollOffset();
  initSections();
  initInPageLinks();
}
