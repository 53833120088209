import axios from 'axios';
import gsap from 'gsap';
import map from './map.js';
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);

let source;
let loadMore;

// Handle a click on the "Load more" button:
function handleLoadMore(event) {
  event.preventDefault();

  // Ignore subsequent clicks during an AJAX load:
  if (loadMore.classList.contains('is-loading')) {
    return false;
  }

  // Temporarily disable the "load more" button:
  loadMore.classList.add('is-loading');

  // AJAX load the next page into the items container
  // Don't update history
  ajaxLoad({
    url:  event.target.href,
    mode: 'append',
    src:  'content-items',
    dest: 'content-items'
  }, false);

  // Track load more event with google analytics
  const eventCategory = document.body.dataset.section;
  if (eventCategory) {
    ga('send', {
      hitType: 'event',
      eventCategory: eventCategory,
      eventAction: 'click',
      eventLabel: 'Load more'
    });
  }
}

function maintainScroll(scrollHeight, scrollTop) {
  const newScrollHeight = document.documentElement.scrollHeight;
  const scrollDiff = newScrollHeight - scrollHeight;
  document.documentElement.scrollTop = scrollTop + scrollDiff;
}

// AJAX load from src element to dest element (append or replace)
// @param  object options  params: url, mode, src, dest
function ajaxLoad(options, updateHistory) {
  // If user clicks too quickly on filters cancel and restart ajax calls
  if (source) {
    source.cancel('Operation cancelled by the user.');
  }
  const CancelToken = axios.CancelToken;
  source = CancelToken.source();

  // updateHistory defaults to true:
  updateHistory = (typeof updateHistory === 'undefined') ? true : updateHistory;

  // Get the element we're going to load stuff into:
  const dest = document.getElementById(options.dest);

  // Add an 'is-loading' and 'is-appending'/'is-replacing' state to it:
  let modeClass;
  switch (options.mode) {
    case 'append':  modeClass = 'is-appending'; break
    case 'replace': modeClass = 'is-replacing'; break
  }
  // dest.classList.add('is-loading', modeClass)
  const wrapper = document.querySelector('#filtered-entries');
  wrapper.classList.add('is-loading');

  // AJAX load the contents of src (on the remote page) into dest (on this page):
  axios({
    url: options.url,
    cancelToken: source.token
  })
  .then(function(res) {
    const resDoc = document.createRange().createContextualFragment(res.data)
    // Get the contents only of the target element:
    // (ie. remove the container element)
    const srcElement = resDoc.getElementById(options.src);
    const loadedHTML = srcElement.innerHTML;
    const nextPage = options.mode === 'replace' ? srcElement.dataset.nextpage : srcElement.parentNode.dataset.nextpage;
    const loadedItems = Array.from(srcElement.querySelectorAll('.js-ajax-item'));

    if (loadedItems.length) {
      // Preload all images
      const images = loadedItems.map(item => item.querySelector('img'));
      let imagesLoaded = 0;
      images.forEach((image, i) => {
        const newImage = new Image();
        newImage.onload = () => {
          if (imagesLoaded < images.length -1) {
            imagesLoaded++;
          } else {
            const scrollHeight = document.documentElement.scrollHeight;
            const scrollTop = document.documentElement.scrollTop;
            // ...then append or replace it into 'dest' on the page:
            switch (options.mode) {
              case 'append':
                dest.innerHTML += loadedHTML;
                break;
              case 'replace':
                dest.innerHTML = loadedHTML;
                break;
            }

            wrapper.classList.remove('is-loading');
            ScrollTrigger.refresh();
            if (options.maintainScroll) {
              maintainScroll(scrollHeight, scrollTop);
            }
          }
        }

        newImage.srcset = image.srcset;
      })
    } else {
      const scrollHeight = document.documentElement.scrollHeight;
      const scrollTop = document.documentElement.scrollTop;
      switch (options.mode) {
        case 'append':
          dest.innerHTML += loadedHTML;
          break;
        case 'replace':
          dest.innerHTML = loadedHTML;
          break;
      }

      wrapper.classList.remove('is-loading');
      ScrollTrigger.refresh();
      if (options.maintainScroll) {
        maintainScroll(scrollHeight, scrollTop);
      }
    }

    // ...and update the href of the "Load more" button:
    if (typeof options.updateLoadMore === 'undefined' || options.updateLoadMore) {
      if (nextPage && nextPage.length) {
        loadMore.href = nextPage
        loadMore.style = ''
        loadMore.classList.remove('is-loading')
      } else {
        loadMore.style = 'display: none'
      }
    }

    // Add this AJAX load to the browser's history:
    if (updateHistory && typeof history.pushState !== 'undefined') {
      const state = options
      state.ajax = true
      history.pushState(state, null, options.url)
      // Add html to session sessionStorage
      // sessionStorage.setItem(window.location.href, dest.closest('#content').outerHTML)
    }

    // If a callback was specified:
    if (options.callback) { options.callback() }
  })
  .catch(function(thrown) {
    if (axios.isCancel(thrown)) {
      console.log('Request canceled', thrown.message)
    } else {
      console.log(thrown)
    }
  })
}

function updateFilters(e) {
  let clicked;
  // Check for token - Craft uses this when viewing an unpublished page
  const params = (new URL(document.location)).searchParams;
  const token = params.get('token');
  let base;
  // Check if there are multiple filter forms on the page
  const forms = Array.from(document.querySelectorAll('.js-filter-form'));
  // If yes then sync checkboxes between forms
  if (forms.length > 1 && e) {
    clicked = e.currentTarget;
    let selector;
    if (clicked.id.includes('map')) {
      selector = `${clicked.dataset.group}-${clicked.value}`;
    } else {
      selector = `map-${clicked.dataset.group}-${clicked.value}`;
    }

    const match = document.getElementById(selector);
    if (match) {
      match.checked = clicked.checked;
    };
    if (map) {
      map.updateMarkers();
    };
  }

  const activeFilters = Array.from(document.querySelectorAll('.js-filter-input:checked'))
  let urlString = ''

  if (activeFilters.length) {
    base = token ? location.href.split(token)[0] + token + '&' : location.href.split('?')[0] + '?';

    let filterObjects = activeFilters.map((item) => {
      let obj = {}
      obj.group = item.dataset.group
      obj.value = item.value
      return obj
    })

    // Filter duplicates
    filterObjects = filterObjects.filter((filter, index, self) =>
      index === self.findIndex((item) => (
        item.group === filter.group && item.value === filter.value
      ))
    )

    let grouped = filterObjects.reduce((r, a) => {
     r[a.group] = [...r[a.group] || [], a];
     return r;
    }, {});

    Object.keys(grouped).forEach((key, keyIndex, keyArray) => {
      const array = grouped[key]

      const arrayString = array.reduce((acc, current, index, array) => {
        if (index !== array.length -1) {
          return acc.concat(`${current.value},`)
        } else {
          return acc.concat(current.value)
        }
      }, `${key}=`)

      if (keyIndex !== keyArray.length -1) {
        urlString = urlString.concat(`${arrayString}&`)
      } else {
        urlString = urlString.concat(arrayString)
      }
    })

    urlString = base + urlString;
  } else {
    base = token ? location.href.split(token)[0] + token : location.href.split('?')[0];
    urlString = base;
  }

  let maintainScroll = false;
  if (e) {
    maintainScroll = !!e.currentTarget.closest('.js-map-filter-input');
  }

  ajaxLoad({
    url:  urlString,
    mode: 'replace',
    src:  'ajax-container',
    dest: 'ajax-container',
    filterValues: storeFilterValues(),
    maintainScroll: maintainScroll
  });
}

function storeFilterValues() {
  let filterValues = {};
  const filterItems = document.querySelectorAll('.js-filter-input');
  filterItems.forEach(function(item) {
    filterValues[item.id] = item.checked;
  });
  return JSON.stringify(filterValues);
}

function updateCounter(e) {
  const checkbox = e.target;
  const checked = checkbox.checked;
  const matchingCounter = document.querySelector(`.js-filter-counter[data-count=${checkbox.name}]`);

  if (matchingCounter) {
    let value = matchingCounter.innerHTML;

    if (checked) {
      value++;
    } else {
      value--;
    }

    matchingCounter.innerHTML = value;

    if (value < 1) {
      matchingCounter.classList.remove('filters-counter-is-active');
    } else {
      matchingCounter.classList.add('filters-counter-is-active');
    }
  }
}

function toggleBtnClass(e) {
  const btn = e.currentTarget;
  const currentActive = document.querySelector('.filters-btn-is-active');

  if (currentActive && currentActive !== btn) {
    currentActive.classList.remove('filters-btn-is-active');
  }

  btn.classList.toggle('filters-btn-is-active');
}

function toggleMenu(e) {
  const selector = e.currentTarget.dataset.toggle;
  const matchingMenu = document.querySelector(`.js-filters-menu[data-menu="${selector}"]`);
  const currentActive = document.querySelector('.js-filters-menu.filters-menu-is-active');

  if (currentActive && currentActive !== matchingMenu) {
    gsap.to(currentActive, {
      duration: 0.2,
      autoAlpha: 0,
      height: 0,
      onComplete: () => {
        gsap.set(currentActive, {clearProps: 'height,opacity,visibility'});
        currentActive.classList.remove('filters-menu-is-active');
      }
    });
  }

  if (matchingMenu.classList.contains('filters-menu-is-active')) {
    gsap.to(matchingMenu, {
      duration: 0.2,
      autoAlpha: 0,
      height: 0,
      onComplete: () => {
        gsap.set(matchingMenu, {clearProps: 'height,opacity,visibility'});
        matchingMenu.classList.remove('filters-menu-is-active');
      }
    });
  } else {
    matchingMenu.classList.add('filters-menu-is-active')
    gsap.from(matchingMenu, {
      duration: 0.2,
      autoAlpha: 0,
      height: 0,
      onComplete: () => {
        gsap.set(matchingMenu, {clearProps: 'height,opacity,visibility'});
      }
    });
  }
}

// Turn pagination into an AJAX 'Load more' button:
function initLoadMore() {
  loadMore = document.getElementById('pagination-loadmore');
  if (loadMore) {
    loadMore.addEventListener('click', handleLoadMore);
  }
}

function initFilters() {
  const filterItems = document.querySelectorAll('.js-filter-input');
  if (filterItems) {
    filterItems.forEach((filter) => {
      filter.addEventListener('change', function(e) {
        updateFilters(e);
        updateCounter(e);
      })
    })
  }
}

function initToggleBtns() {
  const toggleBtns = document.querySelectorAll('.js-toggle-btn');
  if (toggleBtns) {
    toggleBtns.forEach((btn) => {
      btn.addEventListener('click', function(e) {
        toggleBtnClass(e);
        toggleMenu(e);
      })
    })
  }
}

function restoreFilters(event) {
  if (event.state.filterValues) {
    const storedValues = JSON.parse(event.state.filterValues);
    const keys = Object.keys(storedValues);
    if (keys.length) {
      keys.forEach(key => {
       const filter = document.querySelector(`#${key}`);
       filter.checked = storedValues[key];
      });
    }

    const filterItems = Array.from(document.querySelectorAll('.js-filter-input'));

    const filterObjects = filterItems.map((item) => {
      let obj = {};
      obj.group = item.dataset.group;
      obj.checked = item.checked;
      return obj;
    })

    let grouped = filterObjects.reduce((r, a) => {
     r[a.group] = [...r[a.group] || [], a];
     return r;
    }, {});

    Object.keys(grouped).forEach(group => {
      grouped[group] = grouped[group].filter(item => item.checked);
      const matchingCounter = document.querySelector(`.js-filter-counter[data-count=${group}]`);
      const value = grouped[group].length;
      if (matchingCounter) {
        matchingCounter.innerHTML = value;
      }

      if (value < 1) {
        matchingCounter.classList.remove('filters-counter-is-active');
      } else {
        matchingCounter.classList.add('filters-counter-is-active');
      }
    })
  }
}

// Allow the back button to work nicely with AJAX-loaded content:
function initPopstate() {
  window.addEventListener('popstate', function(event){
    // If the previous page was AJAX loaded, reload via AJAX
    if (event.state && typeof event.state.ajax !== 'undefined' && !window.location.href.includes('our-people')) {
      restoreFilters(event);
      // but don't push this onto the history, because we're going backwards, not forwards
      ajaxLoad(event.state, false);
    }
  })
}

const filters = {
  resetFilters() {
    const form = document.querySelector('#filters-form');
    const filters = Array.from(document.querySelectorAll('.js-filter-input'));
    const counters = Array.from(document.querySelectorAll('.js-filter-counter'));
    form.reset();
    filters.forEach((item) => {
      item.checked = false;
    })
    counters.forEach((item) => {
      item.innerHTML = 0;
      item.classList.remove('filters-counter-is-active');
    })

    updateFilters();
  },
  init() {
    const filterItems = Array.from(document.querySelectorAll('.js-filter-input'));
    const resetBtns = Array.from(document.querySelectorAll('.js-reset-filters'));
    if (filterItems.length) {
      if (typeof history.replaceState !== 'undefined') {
        // Store an initial state, so the back button works properly when returning
        // to the page you started on:
        const initialState = {
          ajax: true,
          url:  window.location.href,
          mode: 'replace',
          src:  'ajax-container',
          dest: 'ajax-container',
          filterValues: storeFilterValues()
        }
        history.replaceState(initialState, null, window.location.href);
      }

      initPopstate();
      initLoadMore();
      initFilters();
      initToggleBtns();
    }

    if (resetBtns.length) {
      resetBtns.forEach(btn => {
        btn.addEventListener('click', function(e) {
          filters.resetFilters();
          map.resetMapFilters();
          map.resetMarkers();
        });
      })
    }
  }
}

export default filters;
