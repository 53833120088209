import gsap from 'gsap';
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollToPlugin from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);

function resizeListener() {
  // Setup a timer
  let timeout;

  // Listen for resize events
  window.addEventListener('resize', function() {
  	// If there's a timer, cancel it
  	if (timeout) {
  		window.cancelAnimationFrame(timeout);
  	}

    // Setup the new requestAnimationFrame()
  	timeout = window.requestAnimationFrame(function() {
      // Run our scroll functions
      const active = Array.from(document.querySelectorAll('.image-note-is-active'));
      if (!active.length) {
        return;
      }

      active.forEach(item => {
        const dropdown = item.querySelector('.js-image-note-dropdown');
        const height = dropdown.offsetHeight;
        const newHeight = dropdown.firstElementChild.offsetHeight;

        if (height !== newHeight) {
          gsap.set(dropdown, {height: newHeight});
        }
      });
  	});
  }, false);
}

function handleClick(e) {
  if (window.innerWidth < 768) {
    toggleDropdown(e);
  } else {
    toggleModal(e);
  }
}

function hideModal(modal) {
  modal.classList.remove('image-modal-is-active');
  gsap.to(modal, {
    autoAlpha: 0,
    y: 10,
    duration: 0.2,
    ease: "back.out(1.7)",
    onComplete: () => {
      gsap.set(modal, {zIndex: -1});
    }
  });
}

function showModal(modal) {
  modal.classList.add('image-modal-is-active');
  gsap.set(modal, {zIndex: 2});
  gsap.fromTo(modal, {
    autoAlpha: 0,
    y: 10,
  }, {
    autoAlpha: 1,
    y: 0,
    duration: 0.2,
    ease: "back.out(1.7)"
  });
}

function toggleModal(e) {
  const clicked = e.currentTarget;
  const selector = clicked.dataset.modal;
  const modal = document.querySelector(`#${selector}`);
  const current = document.querySelector('.image-modal-is-active');

  if (current === modal) {
    hideModal(modal);
  } else {
    if (current) {
      hideModal(current)
    }
    showModal(modal);
  }
}

function toggleDropdown(e) {
  const clicked = e.currentTarget;
  const selector = clicked.dataset.note;
  const note = document.querySelector(`#${selector}`);
  const dropdown = note.querySelector('.js-image-note-dropdown');

  if (note.classList.contains('image-note-is-active')) {
    note.classList.remove('image-note-is-active');
    gsap.to(dropdown, {
      height: 0,
      autoAlpha: 0,
      duration: 0.2,
    });
  } else {
    note.classList.add('image-note-is-active');
    gsap.set(dropdown, {height: 'auto', autoAlpha: 1});
    const height = dropdown.offsetHeight;
    gsap.from(dropdown, {
      height: 0,
      autoAlpha: 0,
      duration: 0.2,
    });

    gsap.to(window, {duration: 0.2, scrollTo: {y: dropdown, offsetY: window.innerHeight - height}});
  }
}

function initTrigger(image) {
  ScrollTrigger.create({
    trigger: image,
    start: "top center",
    onEnter: () => image.classList.add('image-is-visible'),
  });
}

export default function() {
  const btns = Array.from(document.querySelectorAll('.js-hub-image-mobile-btn'));
  if (!btns.length) {
    return;
  }
  btns.forEach(btn => btn.addEventListener('click', handleClick));
  const closeBtns = Array.from(document.querySelectorAll('.js-hub-image-close'));
  closeBtns.forEach(btn => btn.addEventListener('click', toggleModal));
  resizeListener();

  const images = Array.from(document.querySelectorAll('.js-image-with-markers'));
  if (!images.length) {
    return;
  }
  images.forEach(image => initTrigger(image));
}
