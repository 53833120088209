import search from './search'

const mobileNav = {
  hideMobileNav() {
    const body = document.body;
    body.classList.remove('mobile-nav-is-active');
  },

  init() {
    const mobileNavBtn = document.querySelector('#mobile-nav-btn');
    if (mobileNavBtn) {
      const body = document.body;

      mobileNavBtn.addEventListener('click', function(e) {
        body.classList.toggle('mobile-nav-is-active');
        search.hideSearch();

        const doc = document.documentElement;
        doc.style.setProperty('--vh', (window.innerHeight*.01) + 'px');
      })

      document.addEventListener('keydown', (e) => {
        if (e.code === 'Escape') {
          this.hideMobileNav();
        }
      })
    }
  }
}

export default mobileNav
