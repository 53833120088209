import Glide from '@glidejs/glide';

const body = document.body;
const activeTextClass = 'carousel-text-active';
const el = document.getElementById('home-carousel');
let carousel;
let currentPlayer;
let playerArray = [];

function pauseCurrentVideo() {
  const currentVideoContainer = document.querySelector('.video-is-active');
  if (currentVideoContainer) {
    currentVideoContainer.classList.remove('video-is-active');
  }
  if (currentPlayer) {
    currentPlayer.player.pauseVideo();
  }
}

function setApiReady(params) {
  window.onYouTubeIframeAPIReady = function() {
    newYouTubePlayer(params);
  }
}

function newYouTubePlayer(params) {
  const { container, id } = params;
  const parent = container.closest('.js-home-carousel-slide');
  const newDiv = document.createElement('div');
  newDiv.classList.add('glide__video-container', 'js-home-carousel-iframe-container');
  container.prepend(newDiv);
  const iframeContainer = container.querySelector('.js-home-carousel-iframe-container');
  const player = new YT.Player(iframeContainer, {
    videoId: id,
    playerVars: {
      'autoplay': 1,
      'controls': 0,
      'modestbranding': 1
    },
    events: {
      'onReady': onPlayerReady,
    }
  });

  function onPlayerReady() {
    const playerObject = {
      id: id,
      player: player
    }
    currentPlayer = playerObject;
    playerArray.push(playerObject);
    parent.classList.add('video-is-active');
  }
}

function initResizeListener() {
  // Setup a timer
  let timeout;
  // Listen for resize events
  window.addEventListener('resize', function() {
  	// If there's a timer, cancel it
  	if (timeout) {
  		window.cancelAnimationFrame(timeout);
  	}
      // Setup the new requestAnimationFrame()
  	timeout = window.requestAnimationFrame(function() {
      // Run our resize functions
  		positionControls();
      textHeight();
  	});
  }, false);
}

function positionControls() {
  const el = carousel.selector;
  const image = el.querySelector('.js-glide-image');
  const controls = el.querySelector('.glide__arrows');
  controls.style.width = `${image.offsetWidth}px`;
}

function textHeight() {
  const container = document.getElementById('home-carousel-texts');
  const els = Array.from(document.querySelectorAll('.js-home-carousel-text'));
  const heights = els.map(el => el.offsetHeight);
  const tallest = Math.max(...heights);
  container.style.height = `${tallest}px`;
}

function updateText(a) {
  const activeText = document.querySelector(`.${activeTextClass}`);
  activeText.classList.remove(activeTextClass);
  const newActive = document.getElementById(`home-carousel-text-${carousel.index}`);
  newActive.classList.add(activeTextClass);
}

function calcPeek() {
  if (window.innerWidth > 768) {
    return window.innerWidth*0.25;
  } else {
    return 0;
  }
}

function updatePeek() {
  carousel.update({
    peek: {
      before: calcPeek(),
      after: calcPeek()
    },
  });
}

function playVideo() {
  // Pause carousel autoplay
  carousel.pause();
  const activeSlide = carousel.selector.querySelector('.glide__slide--active');
  const videoContainer = activeSlide.querySelector('.js-home-carousel-video');
  if (!videoContainer) {
    return;
  }
  activeSlide.classList.add('video-is-active');
  const url = videoContainer.dataset.videoUrl;
  const id = url.split('.be/')[1];
  const params = {
    container: videoContainer,
    id: id
  }
  // Check if player has already been initialised
  const playerObject = playerArray.find(item => item.id === id);
  if (playerObject) {
    playerObject.player.playVideo();
    currentPlayer = playerObject;
    return;
  }
  if (window.YT) {
    newYouTubePlayer(params);
    return;
  }
  setApiReady(params);
  const tag = document.createElement('script');
  tag.id = 'youtube-iframe-script';
  tag.src = 'https://www.youtube.com/iframe_api';
  const firstScriptTag = document.getElementsByTagName('script')[0];
  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
}

export default function() {
  if (!el) {
    return;
  }

  carousel = new Glide(el, {
    type: 'carousel',
    dragThreshold: false,
    autoplay: 5000,
    animationDuration: 1000,
    peek: {
      before: calcPeek(),
      after: calcPeek()
    },
  });

  carousel.on('mount.after', function() {
    positionControls();
    textHeight();
    const videoCovers = Array.from(document.querySelectorAll('.js-home-carousel-video-overlay'));
    videoCovers.forEach(item => item.addEventListener('click', playVideo));
    // Remove homepage loader animation class
    body.classList.remove('page-home-loading');  
  });

  carousel.on('resize', updatePeek);
  carousel.on('run.before', pauseCurrentVideo);
  carousel.on('run', updateText);
  carousel.mount();
  initResizeListener();
}
